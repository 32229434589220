'use client';

import { AppShell } from '@mantine/core';
import { PropsWithChildren } from 'react';

import styles from './index.module.scss';

export default function UnauthorizedLayout (props: PropsWithChildren) {
    return (
        <AppShell className={styles.unauthorizedLayout}>
            <AppShell.Main className={styles.unauthorizedLayoutMain}>
                {props.children}
            </AppShell.Main>
        </AppShell>
    );
}